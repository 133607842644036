.container.minter_container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
}
.container.minter_container > h2 {
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0em;
  color: #ffffff;
}
.container.minter_container > h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  color: rgba(232, 235, 238, 0.85);
}
.container.minter_container > h5 > span {
  color: #bf1818b8;
}
.mint_value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 38px;
  text-align: center;
  color: rgba(232, 235, 238, 0.85);
}
.mint_section {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.mint_section > h5 {
  color: rgba(232, 235, 238, 0.85);
}
.mint_box {
  width: 50%;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  margin-bottom: 2rem;
}
.mint {
  width: 30%;
  height: 44px;
  border: 1px solid rgba(232, 235, 238, 0.85);
  box-sizing: border-box;
  border-radius: 25px;
  display: flex;
  gap: 1.3rem;
  justify-content: center;
  align-items: center;
  color: rgba(232, 235, 238, 0.85);
}
.mint > button {
  background: transparent;
  width: 37px;
  height: 24px;
  color: rgba(232, 235, 238, 0.85);
}
.circle_button {
  width: 44px;
  height: 44px;
  border: 1px solid rgba(232, 235, 238, 0.85);
  box-sizing: border-box;
  border-radius: 25px;
  background: transparent;
  color: rgba(232, 235, 238, 0.85);
}
.btn.btn-primary {
  margin-bottom: 1.2rem;
}
.contract > a {
  text-decoration: underline;
  width: 119px;
  height: 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: rgba(232, 235, 238, 0.85);
}
@media screen and (max-width: 800px) {
  .container.minter_container {
    width: 100%;
  }
  .mint_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    align-items: center;
  }
  .circle_button {
    display: none;
  }
  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
  }
  .contract {
    margin-top: 1rem;
  }
}
